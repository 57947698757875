<template>
  <div>
    <!-- picAC12C63E0008D24C90AFB3E6B51B5C39.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/mln-pellet-blaster.jpg">
    <p>Loads of folks are already flaunting the new LEGO Universe Electro-pellet Inductor on their My LEGO Network pages! This new module produces pellets twice as fast as the original Plastic Inductor and it helps you show off your Creation Lab masterpieces!
    </p>
    <p>
      To get this marvelous new My LEGO Network module, simply sign up for the free LEGO Universe email newsletter! Each newsletter currently includes an exclusive VIP code that'll get you a blueprint to build your own Electro-pellet Inductor!
    </p>
    <p>
      <router-link :to="{ name: 'newsletter', params: {} }">Sign up</router-link>
      before <b>Sunday, March 22</b> to get the VIP code in the next issue of the LEGO Universe newsletter!
    </p>
  </div>
</template>
